body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-open {
  color: green;
}
.text-closed {
  color: red;
}

.gm-style-iw {
  max-width: 90vw !important;
}
.gm-style-iw-d {
  overflow-x: hidden !important;
}
.gm-ui-hover-effect {
  top: 4px !important;
  right: 4px !important;
  width: 30px !important;
  height: 30px !important;
}
.gm-ui-hover-effect > img {
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
  padding: 4px;
}